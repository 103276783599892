import { CallToAction } from '#app/components/marketing/call-to-action.tsx'
import { Hero } from '#app/components/marketing/hero.tsx'
import { Pricing } from '#app/components/marketing/pricing'
import { PrimaryFeatures } from '#app/components/marketing/primary-features.tsx'
import { json, type MetaFunction } from '@remix-run/node'
import { Explainer } from '../../components/marketing/explainer.tsx'

export const meta: MetaFunction = () => [{ title: 'ClientCruise' }]

export async function loader() {
	// const publicTenantId = (
	// 	await db.query.tenantSchema.findFirst({
	// 		where: eq(tenantSchema.name, 'Public Gallery'),
	// 		columns: { id: true },
	// 	})
	// )?.id
	// invariantResponse(publicTenantId, 'Public Gallery tenant not found')

	return json({})
}

export default function Index() {
	return (
		<>
			<Hero />
			<Explainer />
			<PrimaryFeatures />
			{/* <SecondaryFeatures /> */}
			<CallToAction />
			<Pricing />
			{/* <Faqs /> */}
		</>
	)
}
