import { Container } from '../container.tsx'

interface Feature {
	// description: string
	// icon: IconName
	image: string
	// title: string
}

const copy = {
	main: 'Put your clients first with a Customer Hub',
	sub: '',
}

const features = [
	{
		image: 'usage.png',
	},
	{
		image: 'support.png',
	},
	{
		image: 'features-main.png',
	},
	{
		image: 'features-details.png',
	},
	{
		image: 'training.png',
	},
	{
		image: 'projects.png',
	},
] satisfies Feature[]

export function PrimaryFeatures() {
	return (
		<section
			aria-label={copy.main}
			className="relative overflow-hidden bg-primary pt-14 sm:py-16 pb-8"
			id="features"
		>
			<Container>
				<h2 className="text-center text-white text-3xl sm:text-4xl font-bold">
					{copy.main}
				</h2>
				<div className="grid gap-4 md:grid-cols-2 mt-8 md:gap-8">
					{features.map(({ image }) => (
						<img
							alt=""
							className="object-contain overflow-hidden shadow-lg rounded-lg shadow-black place-self-center"
							key={image}
							src={`/img/screenshots/${image}`}
						/>
					))}
				</div>
			</Container>
		</section>
	)
}
