import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '../ui/card'
import { Icon, IconName } from '../ui/icon'

const overviewCards: { description: string; icon: IconName; title: string }[] =
	[
		{
			description:
				'Users never have to leave your product to get all the info they need',
			icon: 'component',
			title: 'Embedded in your product',
		},
		{
			description:
				"We'll help with the data engineering to make your product analytics useful to users",
			icon: 'factory',
			title: 'Integrated with your product analytics',
		},
		{
			description: 'Give your clients the info they need to self-service',
			icon: 'list-checks',
			title: 'Save your account teams time',
		},
		{
			description:
				'Customize the experience for each customer and user based on their persona, industry, size, and more',
			icon: 'box',
			title: 'Segmentation',
		},
	]

export function Explainer() {
	return (
		<section className="relative overflow-hidden py-8">
			<div className="grid md:grid-cols-2 gap-4 sm:p-4">
				{overviewCards.map(({ description, icon, title }) => (
					<Card key={title}>
						<CardHeader>
							<div className="text-left">
								<Icon name={icon} />
							</div>
							<CardTitle>{title}</CardTitle>
							<CardDescription>{description}</CardDescription>
						</CardHeader>
						<CardContent></CardContent>
					</Card>
				))}
			</div>
		</section>
	)
}
