import ButtonLink from '../link-button.tsx'

const copy = {
	main: 'Your SaaS product needs a Customer Hub',
	sub: 'Increase customer engagement, usage, and retention with a Customer Hub that integrates with your SaaS product.',
}

const screenshotName = 'external-top.png'

export function Hero() {
	return (
		<div className="relative isolate overflow-hidden">
			<svg
				aria-hidden="true"
				className="absolute inset-0 -z-10 size-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
			>
				<defs>
					<pattern
						height={200}
						id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
						patternUnits="userSpaceOnUse"
						width={200}
						x="50%"
						y={-1}
					>
						<path d="M.5 200V.5H200" fill="none" />
					</pattern>
				</defs>
				<rect
					fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
					height="100%"
					strokeWidth={0}
					width="100%"
				/>
			</svg>
			<div
				aria-hidden="true"
				className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
			>
				<div
					className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
					style={{
						clipPath:
							'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
					}}
				/>
			</div>
			<div className="mx-auto max-w-7xl px-6 pb-8 pt-10 sm:pb-12 lg:flex lg:px-8 lg:pt-40">
				<div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
					{/* <div className="mt-24 sm:mt-32 lg:mt-16">
						<ButtonLink to="#" className="inline-flex space-x-6">
							<span className="rounded-full bg-primary/10 px-3 py-1 text-sm font-semibold leading-6 text-primary ring-1 ring-inset ">
								Latest updates
							</span>
							<span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
								<span>Just shipped v1.0</span>
								<ChevronRightIcon
									className="size-5 text-gray-500"
									aria-hidden="true"
								/>
							</span>
						</ButtonLink>
					</div> */}
					<h1 className="mt-10 text-4xl font-bold tracking-tight text-accent-foreground sm:text-6xl">
						{copy.main}
					</h1>
					<p className="mt-6 text-lg leading-snug text-secondary-foreground">
						{copy.sub}
					</p>
					<div className="mt-10 flex items-center gap-x-6">
						{/* <ButtonLink
							to="/signup"
							className="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-primary shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
						>
							Get started
						</ButtonLink> */}
						<ButtonLink
							className="text-sm font-semibold leading-6 text-white"
							to="/contact"
						>
							Contact Us
							<span aria-hidden="true" className="ml-1">
								→
							</span>
						</ButtonLink>
					</div>
				</div>
				<div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
					<div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
						<img
							alt="App screenshot"
							className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
							height={1442}
							src={`/img/screenshots/${screenshotName}`}
							width={2432}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
